@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-dark: rgba(255, 255, 255, 0);
  --primary-dark-2: rgb(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0, 0, 0, 0.7);
  --primary-green: green;
}

body {
  font-family: "Roboto Condensed" sans-serif !important;
}
/***************************** Navbar *****************************/
nav {
  z-index: 2;
}
.logo {
  width: 6rem;
}
nav a.nav-link {
  /* font-weight: 500; */
  font-size: 0.8rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: #ffffff !important;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid rgba(255, 255, 255, 0);
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
  max-width: 6rem;
  padding-top: 1rem !important;
  height: 6rem !important;
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 1rem;
  }
}

.navbar-nav > li > a.active,
.navbar-nav > li > a.active:focus {
  border-top: 0.1875rem solid #ffffff;
  border-top: 0.1875rem solid var(--primary-white);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid #ffffff;
  border-top: 0.1875rem solid var(--primary-white);
}

/***************************** Header *****************************/

.header-wrapper {
  position: relative;
  background-color: #343a40;
  height: 84vh;
  margin-top: 16vh;
}

.main-info {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: #ffffff;
  color: var(--primary-white);
  text-transform: capitalize;
}

/***************************** Typed Text *****************************/
.typed-text {
  font-size: 2rem;
  color: #ffffff;
  color: var(--primary-white);
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem;
  }
}

/***************************** Main Offer Button *****************************/
.btn-main-offer {
  border: 1px solid #ef4035;
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: #ffffff;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: #f33501;
  background-color: var(--primary-hover-red);
  transition: 0.2s ease-in-out;
}

/***************************** Particles *****************************/
.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media (max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/***************************** About *****************************/

.photo-wrap {
  display: felx;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.profile-img {
  width: 24rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid #ffffff;
  border: 1px solid var(--primary-white);
}

.about-heading {
  margin-bottom: 2rem;
}

@media (max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

/***************************** Education *****************************/

.education {
  background-color: #343a40;
  color: #ffffff;
  color: var(--primary-white);
  padding-bottom: 2em;
}
.education-heading {
  padding-top: 1em;
  padding-bottom: 1em;
}
.school-name {
  padding-top: 1em;
  padding-bottom: 1em;
}

.courses > p {
  position: relative;
  float: left;
}
.school-wrap {
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

/***************************** Portfolio *****************************/
.portfolio-wrapper {
  background: #f4f4f4;
  background: var(--primary-light-grey);
  padding: 3rem 0;
}

.portfolio-image {
  width: 30rem;
  padding: 0 0.5 rem;
}

.project-row {
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
}

/* .image-box-wrapper {
  text-align: center;
  align-items: center;
} */

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

.overflow {
  position: absolute;
  opacity: 0;
  background: #000000;
  background: var(--primary-black);
  width: 30rem;
  height: 14rem;
  top: 0;
  border-radius: 0.5rem;
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -3rem;
  margin-top: 1rem;
  color: #ffffff;
  color: var(--primary-white);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 80%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

@media (max-width: 768px) {
  .portfolio-image-box {
    width: 100%;
    margin-left: 0;
  }
  .portfolio-image-box img {
    width: 100%;
    padding: 0 !important;
    margin: 0;
  }
  .portfolio-image-box .overflow {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    margin: 0;
  }
}

/***************************** Overlay *****************************/
.modal-close {
  display: block;
  height: 50px;
  -webkit-align-self: "center";
          align-self: "center";
  -webkit-align-items: "center";
          align-items: "center";
  text-align: "right";
}

.center-items {
  text-align: center;
  margin-right: 1rem;
}

.portfolio-modal-image {
  width: "100%";
}
.participate-intro {
  text-align: "right";
}
/* .overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-y: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay {overflow-y: auto;}
  .overlay a {font-size: 20px}
  .overlay .closebtn {
  font-size: 40px;
  top: 15px;
  right: 35px;
  }
} */

/***************************** Contact *****************************/
.contact {
  background-color: #343a40;
  color: #f4f4f4;
  color: var(--primary-light-grey);
  padding: 3rem 0;
}

.contact h1 {
  color: #f4f4f4;
  color: var(--primary-light-grey);
  margin-bottom: 2rem;
}

.contact p {
  font-size: 1.2rem;
}

.contact input,
.contact textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid #666666;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-left: 0.125rem solid #666666;
  border-left: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-gray);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1.5rem;
  padding-left: 1rem !important;
}

.contact .container {
  padding: 4rem 0;
}

.contact input:focus,
.contact textarea:focus {
  background: transparent;
  color: var(--primary-light);
  border-left: 0.125rem solid #f4f4f4;
  border-left: 0.125rem solid var(--primary-light-grey);
  border-bottom: 0.125rem solid #f4f4f4;
  border-bottom: 0.125rem solid var(--primary-light-grey);
  transition: 0.3s ease-in-out;
}
.contact-prompt {
  text-align: center;
}
.prompt-message {
  display: inline-block;
  text-align: left;
}

.contact textarea {
  height: 6rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 50%;
  text-align: center;
  background: none;
}

.error-message {
  color: #ffffff;
  color: var(--primary-white);
  text-align: left;
}
@media (max-width: 768px) {
  .contact {
    overflow-x: hidden;
  }
  .contact .container {
    padding: 0 1rem;
  }
  .contact p {
    padding: 0 1rem;
  }
  .prompt-message {
    margin-top: 1rem;
    padding: 0;
  }
  .contact-prompt {
    text-align: left;
  }
}
/***************************** Footer *****************************/
.footer {
  background: #000000;
  background: var(--primary-black);
  color: #666666;
  color: var(--primary-dark-grey);
  padding: 3rem;
  text-align: center;
}

.footer a {
  text-decoration: none;
  color: #666666;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: #f4f4f4;
  color: var(--primary-light-grey);
  cursor: pointer;
}

.backToTop:hover {
  text-decoration: none;
  color: #f4f4f4;
  color: var(--primary-light-grey);
  cursor: pointer;
}

.backToTop {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

